<script setup lang="ts">
</script>
<template>
  <div
    class="top"
    id="top"
  >
    <div class="top-content top-container">
      <div class="top-content-text">
        <h1 class="top-content-text-title">
          Перемещаем клиентов <br>из цифрового мира в <br>Ваш реальный бизнес !
        </h1>
        <div class="top-content-left">
          <img
            class="top-content-left-image"
            src="@/assets/images/svg/top-sec-img.svg"
            alt="">
        </div>
        <div class="">
          <RouterLink
            class="top-content-text-feedback"
            @click.passive="
            () => {
              scrollTo('#feedback', 2900, { offset: -50 })
          }"
            :to="`/`">
            <p class="top-content-text-feedback-item">
              Оставьте заявку
            </p>
          </RouterLink>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.top-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 95% !important;
  margin-left: auto;
  margin-right: auto;
  @media (orientation: landscape){
    width: 75% !important;
  }
}
.top {
  width: 100%;
  height: 100%;
  &-content {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: get-vw(550px);
    @media (orientation: landscape){
      margin-top: get-vw(200px);
    }
    &-left {
      width: 100%;
      margin-top: get-vw(-200px);
      &-image {
        height: 100%;
        width: 100%;
      }
      @media (orientation: landscape){
        margin-top: get-vw(-70px);
      }
    }
    &-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      &-title {
        font-style: normal;
        font-weight: 600;
        font-size: get-vw(140px);
        line-height: get-vw(155px);
        padding-top: get-vw(80px);
        padding-bottom: get-vw(100px);
        color: #000000;
        padding-left: get-vw(40px);
        border-left: get-vw(17px) solid #000000;
        @media (orientation: landscape){
          font-size: get-vw(80px);
          line-height: get-vw(100px);
          padding-top: get-vw(80px);
          padding-bottom: get-vw(100px);
          color: #000000;
          padding-left: get-vw(40px);
          border-left: get-vw(10px) solid #000000;
        }
      }
      &-feedback {
        margin-left: auto;
        margin-right: auto;
        width: get-vw(900px);
        height: get-vw(180px);
        border-radius: get-vw(70px);
        display: flex;
        justify-content: center;
        align-items: center;
        transition: color 0.4s;
        cursor: pointer;
        text-decoration: none;
        border: get-vw(2px) solid #000000;
        color: #FFFFFF;
        background: #000000;
        margin-top: get-vw(-40px);
        text-align: center;
        @media (orientation: landscape){
          width: get-vw(700px);
          height: get-vw(130px);
          border-radius: get-vw(40px);
          margin-top: get-vw(40px);
        }
        &-item {
          font-style: normal;
          font-weight: 700;
          font-size: get-vw(75px);
          @media (orientation: landscape){
            font-size: get-vw(55px);
          }
        }
      }
    }
  }
}
</style>
